import React, { useState, Suspense } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Autoplay, EffectFade } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/scss"

import Search from "./Search"

const TextTransitionLazy = React.lazy(() => import("./TextTransitionLazy"))

const Hero = ({ data }) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const isSSR = typeof window === "undefined"

  const displayCountries = ["MU", "BL"]

  return (
    <>
      <div className="flex flex-col hero sm:relative">
        <div className="relative z-10 order-last h-full mt-6 pointer-events-none sm:order-first sm:mt-0">
          <div className="container flex items-end h-full pb-12">
            <div className="w-full">
              <h1 className="hidden mb-4 text-3xl font-bold text-white font-headline md:text-4xl sm:block">
                {data.home.heroTitle.replace("{city}", "")}{" "}
                <span className="inline text-white">
                  {!isSSR && (
                    <Suspense
                      fallback={
                        <span>
                          {data.home.heroSlider[slideIndex].city.name}
                        </span>
                      }
                    >
                      <TextTransitionLazy
                        text={
                          displayCountries.includes(
                            data.home.heroSlider[slideIndex].city.country.code
                          )
                            ? data.home.heroSlider[slideIndex].city.country.name
                            : data.home.heroSlider[slideIndex].city.name
                        }
                      />
                    </Suspense>
                  )}
                </span>
              </h1>
              <Search data={data} />
            </div>
          </div>
        </div>
        <Swiper
          modules={[Autoplay, EffectFade]}
          slidesPerView={1}
          speed={500}
          loop
          lazy
          grabCursor
          effect="fade"
          autoplay={{
            delay: 5000,
          }}
          className="relative inset-0 order-first w-full sm:absolute sm:z-0 sm:order-last h-96 sm:h-auto"
          onSlideChange={e => setSlideIndex(e.realIndex)}
        >
          {data.home.heroSlider &&
            data.home.heroSlider.map((item, index) => (
              <SwiperSlide className="relative bg-black" key={index}>
                <GatsbyImage
                  style={{
                    objectFit: "cover",
                    width: "100%",
                  }}
                  image={item.gallery[0].gatsbyImageData}
                  className="h-full opacity-75"
                  alt={data.home.heroTitle}
                />
              </SwiperSlide>
            ))}
          <span
            slot="container-end"
            className="absolute z-50 block bottom-4 left-4 right-4 sm:hidden"
          >
            <h1 className="text-3xl font-bold text-white font-headline md:text-4xl">
              {data.home.heroTitle.replace("{city}", "")}{" "}
              <span className="inline text-gold">
                {!isSSR && (
                  <Suspense
                    fallback={
                      <span>{data.home.heroSlider[slideIndex].city.name}</span>
                    }
                  >
                    <TextTransitionLazy
                      text={data.home.heroSlider[slideIndex].city.name}
                    />
                  </Suspense>
                )}
              </span>
            </h1>
          </span>
        </Swiper>
      </div>
    </>
  )
}

export default Hero
